import React, { useState } from "react";
import { logo } from "../asset/Common";
import { Link } from "react-router-dom";
import { getToken, isUserLoggedOut } from "../Utils/utils";

const MenuItem = ({ link, text, handleOpen }) => (
  <Link
    to={link}
    className="text-white font-Spartan-Regular text-[18px]"
    onClick={handleOpen}
  >
    {text}
  </Link>
);

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const userToken = getToken();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    isUserLoggedOut();
    alert("Logged out");
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <header className="sticky top-0 z-[60]">
      <nav className="flex flex-row justify-between w-full  z-50 bg-black px-10 py-4 items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="LEURLUX Logo" width={160} height={160} />
        </Link>
        <div className="hidden lg:space-y-0 p-5 lg:p-0 items-center z-50 lg:relative  flex-col lg:flex lg:flex-row lg:space-x-5 ">
          <MenuItem link="/" text="Home" />
          <MenuItem link="/#services" text="Services" />
          <MenuItem link="/about-us" text="About" />
          <MenuItem link="/#contact" text="Contact" />
          {userToken?.Token ? (
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="relative font-Spartan-Regular"
            >
              <div className="text-white font-Spartan-Regular text-[18px]">
                <span>My Profile</span>
              </div>
              {showPopup && (
                <div className="absolute right-0 bg-white p-5">
                  <h2 className="text-[#6B7280] font-semibold text-xl">
                    Your Account
                  </h2>

                  <p className="mt-2 text-thirdColor text-sm">
                    {userToken?.email}
                  </p>

                  <div className="mt-4 flex gap-2">
                    <Link
                      to="/myaccount/profile"
                      className="rounded bg-[#6B7280] px-4 py-2 text-sm font-medium text-white uppercase"
                    >
                      Profile
                    </Link>

                    <button
                      onClick={handleLogout}
                      className="rounded border border-[#6B7280] px-4 py-2 text-sm font-medium text-gray-600 uppercase"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className="text-white text-base px-4 font-Spartan-Regular lg:px-5 py-2 mr-2 border-2 border-[#6B7280] rounded-lg"
              >
                Sign in
              </Link>
              <Link
                to="/sign-up"
                className="text-base px-4 lg:px-5 py-2 mr-2 font-Spartan-Regular border-2 border-[#6B7280] rounded-lg bg-[#6B7280] text-white"
              >
                Sign Up
              </Link>
            </>
          )}
        </div>

        {isOpen && (
          <div className="absolute bg-black py-10   top-0 left-0  w-1/2 h-screen justify-start space-y-5  p-5  items-center z-50  flex flex-col lg:hidden  ">
            <MenuItem link="/#top" text="Home" handleOpen={handleOpen} />
            <MenuItem
              link="/#services"
              text="Services"
              handleOpen={handleOpen}
            />
            <MenuItem link="/about-us" text="About" handleOpen={handleOpen} />
            <MenuItem link="/#contact" text="Contact" handleOpen={handleOpen} />
            {userToken?.Token ? (
              <Link
                to="/myaccount/profile"
                className="text-base px-4 lg:px-5 py-2 mr-2 font-Spartan-Regular border-2 border-[#6B7280] rounded-lg bg-[#6B7280] text-white"
                onClick={handleOpen}
              >
                My Account
              </Link>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-white text-base px-4 font-Spartan-Regular lg:px-5 py-2 mr-2 border-2 border-[#6B7280] rounded-lg"
                >
                  Sign in
                </Link>
                <Link
                  to="/sign-up"
                  className="text-base px-4 lg:px-5 py-2 mr-2 font-Spartan-Regular border-2 border-[#6B7280] rounded-lg bg-[#6B7280] text-white"
                >
                  Sign Up
                </Link>
              </>
            )}
            <div
              className="lg:hidden  absolute top-0 right-0 text-white p-5"
              onClick={handleOpen}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        )}

        <div
          className="lg:hidden text-white cursor-pointer"
          onClick={handleOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
      </nav>
    </header>
  );
}

export default Header;
