//SignUp
export const SIGN_UP = "SIGN_UP";

//login
export const SIGN_IN = "SIGN_IN";

//Profile
export const USER_PROFILE = "USER_PROFILE";

//INQUIRY
export const GET_ALL_INQUIRY = "GET_ALL_INQUIRY";
export const DELETE_ONE_INQUIRY = "DELETE_ONE_INQUIRY";
export const UPDATE_ONE_INQUIRY = "UPDATE_ONE_INQUIRY";

//FAVORITE
export const GET_ALL_FAVORITE = "GET_ALL_FAVORITE";

//VILLA
export const GET_ALL_VILLA = "GET_ALL_VILLA";
export const GET_ONE_VILLA = "GET_ONE_VILLA";
export const LIKE_VILLA = "LIKE_VILLA";
export const UNLIKE_VILLA = "UNLIKE_VILLA";

//APARTMENT
export const GET_ALL_APARTMENT = "GET_ALL_APARTMENT";
export const GET_ONE_APARTMENT = "GET_ONE_APARTMENT";
export const LIKE_APARTMENT = "LIKE_APARTMENT";
export const UNLIKE_APARTMENT = "UNLIKE_APARTMENT";

//HOTEL
export const GET_ALL_HOTEL = "GET_ALL_HOTEL";
export const GET_ONE_HOTEL = "GET_ONE_HOTEL";
export const LIKE_HOTEL = "LIKE_HOTEL";
export const UNLIKE_HOTEL = "UNLIKE_HOTEL";

//YACHT
export const GET_ALL_YACHT = "GET_ALL_YACHT";
export const GET_ONE_YACHT = "GET_ONE_YACHT";

//TRANSFER
export const GET_ALL_TRANSFER = "GET_ALL_TRANSFER";
export const GET_ONE_TRANSFER = "GET_ONE_TRANSFER";

//AIRPORTS
export const GET_ALL_AIRPORTS = "GET_ALL_AIRPORTS";
