import { Link } from "react-router-dom";
import { Hand_luggage, Passengers } from "../asset/Common";
import toast from "react-hot-toast";

export const FeatureCard = ({ title, description }) => (
  <div className="bg-white rounded-lg shadow-lg p-6">
    <h3 className="lg:text-xl font-Spartan-SemiBold text-black mb-4">
      {title}
    </h3>
    <p className="text-black">{description}</p>
  </div>
);

export const FeatureItem = ({ passengers, luggage }) => (
  <div className="mt-4 lg:text-2xl flex flex-col justify-start items-center bg-white rounded p-5">
    <h3 className="font-Spartan-SemiBold flex flex-row w-full justify-start items-center gap-5">
      <img src={Passengers} width={24} alt="" />
      {passengers} Passengers
    </h3>
    <p className="font-Spartan-SemiBold flex flex-row w-full justify-start items-center gap-5">
      <img src={Hand_luggage} width={24} alt="" />
      {luggage}
    </p>
  </div>
);

const CarCard = ({ title, imageSrc, price, id, category }) => (
  <Link
    to={`/rentals/elite-car/book/${id}?category=${category}`}
    className="rounded-lg bg-white overflow-hidden p-5 shadow-sm cursor-pointer"
  >
    <img className="" src={imageSrc} alt={title} />
    <div className="font-Spartan-Regular">
      <h1 className="text-xl font-bold text-center py-2">{title}</h1>
      <p className="text-center text-sm font-bold">
        Starting from {price} €/Day
      </p>
    </div>
    <button className="px-4 py-1 bg-black text-white mx-auto block my-2 font-bold rounded font-Spartan-Regular">
      Request
    </button>
  </Link>
);

export const CarList = ({ cars, categoryTitle }) => (
  <div
    className="bg-white grid grid-cols-1 lg:grid-cols-3 gap-5 my-5 text-black p-5"
    id="supercars"
  >
    <div className="col-span-full">
      <h2 className="px-3 lg:px-0 text-3xl font-bold font-Spartan-Regular">
        {categoryTitle}
      </h2>
    </div>
    {cars.map((car, index) => (
      <CarCard key={index} {...car} category={categoryTitle} />
    ))}
  </div>
);

export const handleErrors = (err, decryptData) => {
  try {
    const errorMessages = decryptData(err?.response?.data?.data);
    if (
      errorMessages?.status === false &&
      (err?.response?.status === 401 || err?.response?.status === 403)
    ) {
      // Call isUserLoggedOut function or perform logout logic here
      isUserLoggedOut();
      window.location.reload();
      toast.error(errorMessages?.message);
    } else {
      if (errorMessages?.message) {
        toast.error(errorMessages?.message);
      } else {
        toast.error("An error occurred, Please retry again later");
      }
    }
  } catch (error) {
    // Handle any additional errors during error handling
    console.error("Error handling the error:", error);
  }
};

export const isUserLoggedOut = () => localStorage?.clear();

export const getToken = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const generatePriceOptions = (min, max, step) => {
  const options = [];
  for (let i = min; i <= max; i += step) {
    options.push(i);
  }
  return options;
};

export const calculatePriceRange = (seasonalPricing) => {
  const prices = [];

  if (seasonalPricing.highSeason) {
    prices.push(seasonalPricing.highSeason.price);
  }
  if (seasonalPricing.midSeason) {
    prices.push(seasonalPricing.midSeason.price);
  }
  if (seasonalPricing.lowSeason) {
    prices.push(seasonalPricing.lowSeason.price);
  }
  if (seasonalPricing.holidaySeason) {
    prices.push(seasonalPricing.holidaySeason.price);
  }

  if (prices.length === 0) {
    return { lowest: 0, highest: 0 };
  }

  const lowest = Math.min(...prices);
  const highest = Math.max(...prices);

  return { lowest, highest };
};

export const getStatusStyle = (status) => {
  switch (status) {
    case "inquiry":
      return "text-[#337ab7]";
    case "pending":
      return "text-[#ec971f]";
    case "processing":
      return "text-[#31b0d5]";
    case "canceled":
    case "failed":
      return "text-[#c9302c]";
    case "succeeded":
    case "confirm":
      return "text-[#5ec92c]";
    default:
      return "text-[#000000]";
  }
};
export const getNightsInEachMonth = (startDate, endDate) => {
  const nights = {};
  let currentDate = new Date(startDate);
  const end = new Date(endDate);
  end.setDate(end.getDate()); // Include the end date
  while (currentDate < end) {
    const month = currentDate.toLocaleString("default", { month: "long" });
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const daysInMonth = (nextMonth - currentDate) / (1000 * 60 * 60 * 24);
    const nightsCount = Math.min(
      daysInMonth,
      (end - currentDate) / (1000 * 60 * 60 * 24)
    );
    nights[month] = (nights[month] || 0) + nightsCount;
    currentDate = nextMonth;
  }

  return nights;
};

export const calculatePricePerNight = (nights, seasonalPricing) => {
  const prices = {};
  // Check if the selected months match the longTerm months and calculate accordingly
  const longTermMonths = seasonalPricing.longTerm?.months || [];
  const totalLongTermNights = Object.entries(nights).reduce(
    (total, [month, nightsCount]) => {
      if (longTermMonths.includes(month)) {
        return total + nightsCount;
      }
      return total;
    },
    0
  );
  // If total nights in longTerm months are 30 or more, calculate based on 30-day month
  if (totalLongTermNights >= 30) {
    for (const [month, nightsCount] of Object.entries(nights)) {
      if (longTermMonths.includes(month)) {
        const pricePerDay = parseFloat(seasonalPricing.longTerm.price) / 30;
        prices[month] = pricePerDay * nightsCount;
      }
    }
  } else {
    // Otherwise, calculate based on weekly prices for other seasons
    for (const [month, nightsCount] of Object.entries(nights)) {
      for (const season in seasonalPricing) {
        if (
          season !== "longTerm" &&
          seasonalPricing[season].months.includes(month)
        ) {
          const pricePerDay = parseFloat(seasonalPricing[season].price) / 7;
          prices[month] = pricePerDay * nightsCount;
          break;
        }
      }
    }
  }

  return prices;
};

export const formatDate = (date) => {
  return date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

// Helper functions
export const daysToMonths = (days) => Math.round(days / 30);
export const daysToWeeks = (days) => Math.round(days / 7);
