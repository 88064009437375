import { lazy } from "react";

const RoutesPaths = [
  {
    path: "/",
    component: lazy(() => import("../../Components/Home/Home")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/locations",
    component: lazy(() => import("../../Components/locations/locations")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/about-us",
    component: lazy(() => import("../../Components/AboutUs/AboutUs")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/terms-conditions",
    component: lazy(() =>
      import("../../Components/TermsConditions/TermsConditions")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals",
    component: lazy(() => import("../../Components/Rentals/Rentals")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property",
    component: lazy(() => import("../../Components/Property/Property")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/villas",
    component: lazy(() => import("../../Components/Property/Villas/Villas")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/villa/book/:villaName",
    component: lazy(() => import("../../Components/Property/Villas/VillaBook")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/apartments",
    component: lazy(() =>
      import("../../Components/Property/Apartments/Apartments")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/apartment/book/:apartmentName",
    component: lazy(() =>
      import("../../Components/Property/Apartments/ApartmentsBook")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/hotels",
    component: lazy(() => import("../../Components/Property/Hotel/Hotel")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/property/hotel/book/:hotelName",
    component: lazy(() => import("../../Components/Property/Hotel/Book")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle",
    component: lazy(() => import("../../Components/Lifestyle/Lifestyle")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/transfer",
    component: lazy(() => import("../../Components/Transfer/Transfer")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/transfer/book/:transferName",
    component: lazy(() => import("../../Components/Transfer/Book")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/private-jets",
    component: lazy(() =>
      import("../../Components/Rentals/PrivateJets/PrivateJets")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/private-jet/book",
    component: lazy(() => import("../../Components/Rentals/PrivateJets/Book")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/yachts",
    component: lazy(() => import("../../Components/Rentals/Yachts/Yachts")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/yacht/book/:id",
    component: lazy(() => import("../../Components/Rentals/Yachts/Book")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/elite-cars",
    component: lazy(() =>
      import("../../Components/Rentals/EliteCars/EliteCars")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/rentals/elite-car/book/:id",
    component: lazy(() => import("../../Components/Rentals/EliteCars/Book")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/:lifestyleName",
    component: lazy(() =>
      import("../../Components/Lifestyle/LifestyleCommon/LifestyleCommon")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/:lifestyleName/:itemId",
    component: lazy(() =>
      import("../../Components/Lifestyle/LifestyleCommon/Book")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/personal-training",
    component: lazy(() =>
      import("../../Components/Lifestyle/PersonalTraining/PersonalTraining")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/personal-training/book",
    component: lazy(() =>
      import("../../Components/Lifestyle/PersonalTraining/Book")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/massage",
    component: lazy(() => import("../../Components/Lifestyle/Massage/Massage")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/lifestyle/private-runner",
    component: lazy(() =>
      import("../../Components/Lifestyle/PrivateRunner/PrivateRunner")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/login",
    component: lazy(() => import("../../Components/Auth/Login/Login")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/sign-up",
    component: lazy(() => import("../../Components/Auth/SignUp/SignUp")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/forgot-password",
    component: lazy(() =>
      import("../../Components/Auth/ForgotPassword/ForgotPassword")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import("../../Components/Auth/ResetPassword/ResetPassword")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/myaccount",
    component: lazy(() => import("../../Components/Profile/Profile")),
    meta: {
      authRoute: true,
    },
    children: [
      {
        path: "profile",
        component: lazy(() =>
          import("../../Components/Profile/MyAccount/ProfileDetails")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "favorite",
        component: lazy(() =>
          import("../../Components/Profile/Favorite/Favorite")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "change-password",
        component: lazy(() =>
          import("../../Components/Profile/ChangePassword/ChangePassword")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "booking/pending",
        component: lazy(() =>
          import("../../Components/Profile/Booking/Pending/Pending")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "booking/history",
        component: lazy(() =>
          import("../../Components/Profile/Booking/Pending/Pending")
        ),
        meta: {
          authRoute: true,
        },
      },
    ],
  },
];

export default RoutesPaths;
