import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { thunk } from "redux-thunk"; // Import 'thunk' as a named export
import rootReducer from "./Root-Reducer";
import config from "../Config/Config";

const middlewares = [thunk]; // Use 'thunk' as a named export

if (config.ENVIRONMENT === "local") {
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
