import React, { useState } from "react";

const WhatsAppDialog = ({ onClose }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    const phoneNumber = "+46736700548"; // Replace with the actual phone number

    // Construct the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Redirect to the WhatsApp URL
    window.open(whatsappUrl, "_blank");
  };

  return (
    <section className="fixed bottom-24 right-5 z-50 max-w-sm w-full font-Spartan-Regular">
      <div className="px-2 py-1 rounded-t-xl bg-[#095e54]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5">
            <div className="bg-white text-[#5f9ea0] rounded-full p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 "
              >
                <path d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z" />
              </svg>
            </div>
            <div className="font-Spartan-Regular text-white">
              <h1 className="text-lg font-Spartan-SemiBold">Support</h1>
              <span className="text-xs">Typically replies quickly.</span>
            </div>
          </div>
          <div>
            <div className="text-white cursor-pointer" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full WhatsApp_theme">
        <div className="bg-white rounded-md w-fit  px-4 py-2  relative">
          <h1 className="text-sm font-Spartan-SemiBold text-gray-500">Support</h1>
          <p className="text-sm">
            Hello, How can we assist <br /> you?
          </p>
        </div>
      </div>
      <div className="w-full p-4 bg-[#f0f0f0] flex flex-col rounded-b-xl">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Your message"
          className=" appearance-none border-none rounded-3xl w-full p-3 text-[#666] leading-tight "
        />
        <button
          onClick={handleSend}
          className="mt-3 inline-block rounded-full bg-[#4fce5d] py-1.5 text-lg font-bold text-white  w-[80%] mx-auto"
        >
          Send
        </button>
      </div>
    </section>
  );
};

export default WhatsAppDialog;
