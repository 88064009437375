import React from "react";
import { fb, instagram, logo, tiktok } from "../asset/Common";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
function Footer() {
  return (
    <div className="w-full">
      <div className=" py-10 px-10 bg-black gap-5 lg:gap-0  container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center items-start gap-5">
          <div className="flex flex-col justify-between gap-5">
            <img src={logo} className="w-40" alt="LEURLUX Logo" />
            <div>
              <p className="text-gray-300 font-Spartan-Light text-base">
                Praceta Libânia do Carmo Galvão, 2, 1C, 2700 394 Amadora,
                Portugal
              </p>
              <a
                className="cursor-pointer flex items-start font-Spartan-Regular text-sm gap-2 mt-2"
                target="_blank"
                href="mailto:Info@leurlux.com"
                rel="noreferrer"
              >
                <MdEmail className="text-white text-xl" />
                <span className="text-white">Info@leurlux.com</span>
              </a>
            </div>
            <div />
          </div>
          <div className="flex flex-col justify-between items-start lg:items-center gap-2">
            <div className="flex flex-col justify-between gap-2">
              <h1 className="text-2xl font-Spartan-Regular text-white ">
                LUXURY CONCIERGE
              </h1>
              <Link
                className="text-base font-Spartan-Light text-gray-300"
                to="/locations"
              >
                Locations
              </Link>
              <Link
                className="text-base font-Spartan-Light text-gray-300"
                to="/#services"
              >
                Services
              </Link>
              <Link
                className="text-base font-Spartan-Light text-gray-300"
                to="/about-us"
              >
                About Us
              </Link>
              <a
                className="text-base font-Spartan-Light text-gray-300"
                href="/#contact"
              >
                Contact
              </a>
              <Link
                className="text-base font-Spartan-Light text-gray-300"
                to="/terms-conditions"
              >
                Terms &amp; Conditions
              </Link>
            </div>
          </div>
          <div className="flex flex-col justify-start lg:justify-end items-start lg:items-center gap-2 ">
            <h1 className="text-2xl font-Spartan-Regular text-white ">
              FOLLOW US
            </h1>
            <div className="flex flex-row gap-5">
              {/* <a
                className="cursor-pointer flex flex-col items-center font-Spartan-Regular text-sm gap-2"
                target="_blank"
                href="https://wa.me/+46762755448"
                rel="noreferrer"
              >
                <img className="w-10 h-10" src={whatsapp} alt="" />
                <span className="text-white">Whatsapp</span>
              </a> */}
              <a
                className="cursor-pointer flex flex-col items-center font-Spartan-Regular text-sm gap-2"
                target="_blank"
                href="https://www.facebook.com/share/BTi9MhoxuwkUEebM/?mibextid=LQQJ4d"
                rel="noreferrer"
              >
                <img className="w-10 h-10" src={fb} alt="" />
                <span className="text-white">Facebook</span>
              </a>
              <a
                className="cursor-pointer flex flex-col items-center font-Spartan-Regular text-sm gap-2"
                target="_blank"
                href="https://www.tiktok.com/@leurlux?_t=8myFWEkNS7M&_r=1"
                rel="noreferrer"
              >
                <img className="w-10 h-10" src={tiktok} alt="" />
                <span className="text-white">TikTok</span>
              </a>

              <a
                className="cursor-pointer flex flex-col items-center font-Spartan-Regular text-sm gap-2"
                target="_blank"
                href="https://www.instagram.com/leurlux?igsh=MXEybXk3ZDh1anRn&utm_source=qr"
                rel="noreferrer"
              >
                <img className="w-10 h-10" src={instagram} alt="" />
                <span className="text-white">Instagram</span>
              </a>
            </div>
          </div>
        </div>
        {/* <Link
          to="https://themajesticai.com"
          target="_blank"
          className="text-white flex font-Spartan-Light items-center gap-3 justify-end mt-5"
        >
          Developed By
          <img src={MajesticAi} alt="" width={80} />
        </Link> */}
      </div>
    </div>
  );
}

export default Footer;
