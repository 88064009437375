const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;
const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;
const config = {
  base: {
    url: API_URL_LOCAL,
  },
  ENVIRONMENT,
};

//CHECK FOR ENVIRONMENT
if (ENVIRONMENT === "prod") {
  config.base.url = API_URL_PROD;
} else {
  config.base.url = API_URL_LOCAL;
}

export default config;
