import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import Router from "./Routers/router";
import { Toaster } from "react-hot-toast";
import Header from "./Components/LayOut/Header";
import Loader from "./Components/Loader";
import Footer from "./Components/LayOut/Footer";
import WhatsAppButton from "./Components/Whatsapp/WhatsAppButton";
import { useLocation } from "react-router-dom";
import { getToken } from "./Components/Utils/utils";
import WelcomePopup from "./Components/WelcomePopup/WelcomePopup";

function App() {
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const shouldRenderNavbar = [
    "/login",
    "/sign-up",
    "/forgot-password",
    "/reset-password",
  ].includes(pathname);
  const userToken = getToken();

  const shouldRenderLegal = ["/terms-of-use", "/privacy-policy"].includes(
    pathname
  );

  useEffect(() => {
    const hasShownWelcomePopup = localStorage.getItem("hasShownWelcomePopup");
    if (userToken?.Token && !hasShownWelcomePopup) {
      setShowWelcomePopup(true);
      localStorage.setItem("hasShownWelcomePopup", "true");
    }
  }, [userToken?.Token]);

  const handleCloseWelcomePopup = () => {
    setShowWelcomePopup(false);
  };

  return (
    <div className="font-Spartan-Regular">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 2500,
        }}
        containerStyle={{
          zIndex: 99999999,
        }}
      />
      <Header />

      <div
        className={`${
          !shouldRenderNavbar && "container "
        } mx-auto bg-white w-full min-h-screen text-black`}
      >
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </div>
      <WhatsAppButton token={userToken?.Token} />
      {showWelcomePopup && (
        <WelcomePopup
          onClose={handleCloseWelcomePopup}
          name={userToken?.username}
        />
      )}
      {!(shouldRenderNavbar || shouldRenderLegal) && <Footer />}
    </div>
  );
}

export default App;
