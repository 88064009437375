import * as types from "./ActionType";

const initialState = {
  Token: {},
  UserProfile: null,
  Inquiry: {},
  Villa: [],
  OneVilla: null,
  Apartment: [],
  OneApartment: null,
  Hotel: [],
  OneHotel: null,
  Favorite: [],
  Yacht: [],
  OneYacht: null,
  Transfer: [],
  OneTransfer: null,
  Airports: [],
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SIGN_IN:
    case types.SIGN_UP:
      return { ...state, Token: action.payload, loading: false };

    // Add cases for UserProfile actions
    case types.USER_PROFILE:
      return { ...state, UserProfile: payload, loading: false };

    // Add cases for Villa actions
    case types.GET_ALL_VILLA:
      return { ...state, Villa: payload, loading: false };
    case types.GET_ONE_VILLA:
      return { ...state, OneVilla: payload, loading: false };
    case types.LIKE_VILLA:
    case types.UNLIKE_VILLA:
      return {
        ...state,
        Villa: state.Villa.map((villa) =>
          villa._id === action.payload._id ? { ...villa, ...payload } : villa
        ),
      };

    // Add cases for Apartment actions
    case types.GET_ALL_APARTMENT:
      return { ...state, Apartment: payload, loading: false };
    case types.GET_ONE_APARTMENT:
      return { ...state, OneApartment: payload, loading: false };
    case types.LIKE_APARTMENT:
    case types.UNLIKE_APARTMENT:
      return {
        ...state,
        Apartment: state.Apartment.map((apartment) =>
          apartment._id === action.payload._id
            ? { ...apartment, ...payload }
            : apartment
        ),
      };

    // Add cases for Hotel actions
    case types.GET_ALL_HOTEL:
      return { ...state, Hotel: payload, loading: false };
    case types.GET_ONE_HOTEL:
      return { ...state, OneHotel: payload, loading: false };
    case types.LIKE_HOTEL:
    case types.UNLIKE_HOTEL:
      return {
        ...state,
        Hotel: state.Hotel.map((hotel) =>
          hotel._id === action.payload._id ? { ...hotel, ...payload } : hotel
        ),
      };

    // Add cases for Yacht actions
    case types.GET_ALL_YACHT:
      return { ...state, Yacht: payload, loading: false };
    case types.GET_ONE_YACHT:
      return { ...state, OneYacht: payload, loading: false };

    // Add cases for Airports actions
    case types.GET_ALL_AIRPORTS:
      return { ...state, Airports: payload, loading: false };

    // Add cases for Transfer actions
    case types.GET_ALL_TRANSFER:
      return { ...state, Transfer: payload, loading: false };
    case types.GET_ONE_TRANSFER:
      return { ...state, OneTransfer: payload, loading: false };

    // Add cases for Inquiry  actions
    case types.GET_ALL_INQUIRY:
      return { ...state, Inquiry: payload, loading: false };
    case types.DELETE_ONE_INQUIRY:
      return {
        ...state,
        Inquiry: {
          ...state.Inquiry,
          inquiry: state.Inquiry.inquiry.filter(
            (inquiry) => inquiry._id !== action.payload.InquiryId
          ),
        },
      };
    case types.UPDATE_ONE_INQUIRY:
      return {
        ...state,
        Inquiry: {
          ...state.Inquiry,
          inquiry: state.Inquiry.inquiry.map((inquiry) =>
            inquiry._id === action.payload.InquiryId
              ? { ...inquiry, ...action.payload.UpdatedInquiry }
              : inquiry
          ),
        },
      };

    // Add cases for Favorite  actions
    case types.GET_ALL_FAVORITE:
      return { ...state, Favorite: payload, loading: false };

    default:
      return state;
  }
};

export default userReducers;
