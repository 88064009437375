import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

const WelcomePopup = ({ onClose, name }) => {
  return (
    <div className="fixed inset-0 top-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-[1000]">
      <div className="relative bg-white p-6 rounded-lg shadow-lg text-center font-Spartan-Regular mx-5">
        <h3 className="md:text-xl text-lg my-4">
          WELCOME TO <span className="text-[#C1B686]">LEURLUX</span>{" "}
          <span className="font-Spartan-SemiBold">{name} </span>
          <br /> Please create your own profile
        </h3>
        <Link
          to="/myaccount/profile"
          onClick={onClose}
          className="bg-primary  text-white px-4 py-2 rounded-md"
        >
          Profile
        </Link>
        <button onClick={onClose} className="absolute top-2 right-2 text-2xl">
          <IoIosCloseCircleOutline />
        </button>
      </div>
    </div>
  );
};

export default WelcomePopup;
